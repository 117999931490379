import React, { useState }  from "react";
import Daily from "./Daily";
import Monthly from "./Monthly";
import moment from "moment";

export default function UsersChart({ dailyAccounts, monthlyAccounts,setSelectedLeague }) {
  const [selectedLeague, setSelectedLeagued] = useState('all');

  const handleLeagueChange = (league) => {
    setSelectedLeague(league);
    setSelectedLeagued(league);
  };
  return (
    <div className="p-6 bg-white w-full mt-6">
      <div className="grid grid-cols-12 gap-5">
        <div className="col-span-9 border-r pr-5 flex flex-col">
          <h4 className="text-[20px] text-black font-semibold mb-4">
            Daily Active Users 
            <span 
              className={`inline-block cursor-pointer mx-2 ${selectedLeague === 'all' ? 'text-black' : 'text-[#5468DB]'}`} 
              onClick={() => handleLeagueChange('all')}
            >
              All Leagues
            </span>
             | 
            <span 
              className={`inline-block cursor-pointer mx-2 ${selectedLeague === '1' ? 'text-black' : 'text-[#5468DB]'}`} 
              onClick={() => handleLeagueChange('1')}
            >
              Amateur
            </span>
            | 
            <span 
              className={`inline-block cursor-pointer mx-2 ${selectedLeague === '2' ? 'text-black' : 'text-[#5468DB]'}`} 
              onClick={() => handleLeagueChange('2')}
            >
              Pro
            </span>
            | 
            <span 
              className={`inline-block cursor-pointer mx-2 ${selectedLeague === '3' ? 'text-black' : 'text-[#5468DB]'}`} 
              onClick={() => handleLeagueChange('3')}
            >
              Stars
            </span>

          </h4>
          <Daily dailyAccounts={dailyAccounts} />
        </div>
        <div className="col-span-3">
          <h4 className="text-[20px] text-black font-semibold mb-4">
            Monthly Active Users (Up to day {moment().format('Do')})
          </h4>
          <Monthly monthlyAccounts={monthlyAccounts} />
        </div>
      </div>
    </div>
  );
}
