export default function Monthly({ monthlyAccounts }) {
  const findPercentage = (param) => {
    let percentage = (param.current_day_sales / param.rest_of_month_sales) * 100
    return percentage;
  };
  
  const parentHeighest = (param) => {
    let percentage = (param.total_month_count * 100) / param.max_number;
    return percentage;
  };

  const formatRoundAndPrice = (amount) => {
    const number = parseFloat(amount);

    if (typeof number === "number" && !Number.isNaN(number) && number !== 0) {
      const roundedNumber = parseFloat(number.toFixed(0));
      return `$${roundedNumber.toLocaleString()}`;
    }

    if (isNaN(number)) {
      return "$0";
    }

    return `$${amount}`;
  };
  return (
    <div className="flex flex-col gap-1">
      {monthlyAccounts?.map((account, key) => (
        <div className="flex items-center" key={key}>
          <div className="w-3/12">
            <p className="text-[16px] text-black font-semibold">
              {account.day}
            </p>
          </div>
          <div className="w-7/12 pl-3 pr-5 relative">
            <div
              className="flex items-center "
            >
              <div className="absolute left-5">
                {formatRoundAndPrice(account.current_day_sales)}
              </div>
              <div className="absolute right-5">
                {formatRoundAndPrice(account.rest_of_month_sales)}
              </div>
              <div
                className="h-[20px] bg-[#FFBB44]"
                style={{ width: `${findPercentage(account)}%` }}
              ></div>
              <div
                className="h-[20px] bg-gray-300"
                style={{ width: `${100 - findPercentage(account)}%` }}
              ></div>
            </div>
          </div>
          <div className="w-2/12">
            <p className="text-[16px] text-black flex">
              {account.current_day_count}/{" "}
              <span className="font-semibold">{account.total_month_count}</span>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}
